var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "userCarAuth" },
    [
      _c(
        "i-header",
        {
          attrs: { title: "授权人", showBack: true },
          on: { "back-event": _vm.toBack },
        },
        [
          _vm.isDsm
            ? _c(
                "div",
                {
                  staticClass: "right_action",
                  attrs: { slot: "right" },
                  on: { click: _vm.navToAppliedList },
                  slot: "right",
                },
                [_vm._v("申请名单")]
              )
            : _vm._e(),
        ]
      ),
      _vm.appliedList.length
        ? _c(
            "i-list",
            {
              attrs: {
                offset: 1,
                finished: _vm.finished,
                "finished-text": "没有更多了",
                "immediate-check": false,
              },
              on: { load: _vm.nextPage },
              model: {
                value: _vm.loading,
                callback: function ($$v) {
                  _vm.loading = $$v
                },
                expression: "loading",
              },
            },
            _vm._l(_vm.appliedList, function (item, index) {
              return _c("div", { key: index, staticClass: "item" }, [
                _c("div", { staticClass: "left" }, [
                  _c("div", { staticClass: "title" }, [
                    _c("div", { staticClass: "title-name" }, [
                      _vm._v(_vm._s(item.name) + " "),
                      _c("div", { staticClass: "sub_title" }, [
                        _vm._v("员工号：" + _vm._s(item.extId)),
                      ]),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "desc",
                      staticStyle: { "margin-top": "0.08rem" },
                    },
                    [
                      _c("i-icon", {
                        attrs: {
                          size: "14",
                          color: "#13161B",
                          name: "icon-qita",
                        },
                      }),
                      _vm._v(_vm._s(item.group6 || "-")),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "desc" },
                    [
                      _c("i-icon", {
                        attrs: {
                          size: "14",
                          color: "#13161B",
                          name: "icon-youxiang",
                        },
                      }),
                      _vm._v(_vm._s(item.email || "（暂无）")),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "right" },
                  [
                    _c(
                      "i-button",
                      {
                        nativeOn: {
                          click: function ($event) {
                            return _vm.remove(item)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ],
                  1
                ),
              ])
            }),
            0
          )
        : _vm._e(),
      !_vm.appliedList.length ? _c("empty-box") : _vm._e(),
      _c("i-add-button", {
        ref: "iAddButton",
        staticStyle: { "z-index": "3" },
        on: { click: _vm.addNew },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }